const errorsServices = error => {
  const result = JSON.parse(error.request.response)
  const { status } = error.request
  let response = ''
  let err = ''
  let code = ''

  if (status === 422) {
    if (result.error) {
      err = Object.values(result.error)
    } else if (result.errors) {
      err = Object.values(result.errors)
    }

    if (err.length > 0) {
      let content = ''

      for (let i = 0; i < err.length; i++) {
        content += `<li class="text-dark" style="list-style: auto; text-align: left">${err[i]}</li>`
      }

      response = `<ul class="nav flex-column">${content}</ul>`
    } else {
      response = result.errors
    }
  }

  if (status === 500) {
    response = `${result.message} (${result.file}, Line ${result.line}) - ${result.exception}`
  }

  if(status === 403 && result.error) {
    result.error.match(/\[([\w\s]*)\]/g)
        .forEach((item) => {
          code = item;
        });
  }

  const HTTP = {
    401: 'Usted no tiene autorización',
    403: `Usted no tiene permiso en esta sección ${code}`,
    404: 'No existe lo que esta buscando',
    405: 'El método que esta buscando no existe',
    409: 'Hay un conflicto de recursos intente de nuevo más tarde',
    422: response,
    500: process.env.VUE_APP_ACTIVE_ALERT ? response : 'En mantenimiento, pedimos disculpas estamos trabajando para usted',
  }

  return HTTP[status]
}

export default errorsServices
