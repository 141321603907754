import mainDefaultConfig from './MainDefaultConfig'
import axiosIns from '@/libs/axios'

class MainService {
  config = { ...mainDefaultConfig }

  axios = null

  constructor() {
    this.axios = axiosIns
  }

  async handleList() {
    const result = await this.axios.get(this.config.indexEndpoint)

    return result
  }
}

export default new MainService()
