<template>
  <div>
    <b-row>
      <b-col
        sm="12"
      >
        <h2 class="text-dark font-weight-bold mb-1">
          Control Ticket
        </h2>
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="6"
        xs="12"
      >
        <b-card
          bg-variant="white"
          text-variant="dark"
          class="shadow"
        >
          <b-card-title class="text-dark">
            <h5 class="text-dark"><strong>CATEGORÍAS</strong></h5>
          </b-card-title>
          <b-card-text>
            <div class="float-left">
              <h3 class="text-dark font-weight-bold">
                {{ categories }}
              </h3>
            </div>
            <div class="float-right">
              <b-avatar
                size="lg"
                variant="primary"
              >
                <feather-icon icon="PieChartIcon" />
              </b-avatar>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="6"
        xs="12"
      >
        <b-card
          bg-variant="white"
          text-variant="dark"
          class="shadow"
        >
          <b-card-title class="text-dark">
            <h5 class="text-dark"><strong>TICKETS ABIERTOS</strong></h5>
          </b-card-title>
          <b-card-text>
            <div class="float-left">
              <h3 class="text-dark font-weight-bold">
                {{ open_tickets }}
              </h3>
            </div>
            <div class="float-right">
              <b-avatar
                size="lg"
                variant="primary"
              >
                <feather-icon icon="BookOpenIcon" />
              </b-avatar>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="6"
        xs="12"
      >
        <b-card
          bg-variant="white"
          text-variant="dark"
          class="shadow"
        >
          <b-card-title class="text-dark">
            <h5 class="text-dark"><strong>TICKETS CERRADOS</strong></h5>
          </b-card-title>
          <b-card-text>
            <div class="float-left">
              <h3 class="text-dark font-weight-bold">
                {{ close_tickets }}
              </h3>
            </div>
            <div class="float-right">
              <b-avatar
                size="lg"
                variant="primary"
              >
                <feather-icon icon="CloudOffIcon" />
              </b-avatar>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="6"
        xs="12"
      >
        <b-card
          bg-variant="white"
          text-variant="dark"
          class="shadow"
        >
          <b-card-title class="text-dark">
            <h5 class="text-dark"><strong>TOTAL DE AGENTES</strong></h5>
          </b-card-title>
          <b-card-text>
            <div class="float-left">
              <h3 class="text-dark font-weight-bold">
                {{ agents }}
              </h3>
            </div>
            <div class="float-right">
              <b-avatar
                size="lg"
                variant="primary"
              >
                <feather-icon icon="BarChart2Icon" />
              </b-avatar>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        sm="12"
      >
        <h2 class="text-dark font-weight-bold mb-1">
          <h5 class="text-dark"><strong>CONTROL DE TALLER</strong></h5>
        </h2>
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="6"
        xs="12"
      >
        <b-card
          bg-variant="white"
          text-variant="dark"
          class="shadow"
        >
          <b-card-title class="text-dark">
            <h5 class="text-dark"><strong>RECIBIDOS</strong></h5>
          </b-card-title>
          <b-card-text>
            <div class="float-left">
              <h3 class="text-dark font-weight-bold">
                {{ received }}
              </h3>
            </div>
            <div class="float-right">
              <b-avatar
                size="lg"
                variant="primary"
              >
                <feather-icon icon="DatabaseIcon" />
              </b-avatar>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="6"
        xs="12"
      >
        <b-card
          bg-variant="white"
          text-variant="dark"
          class="shadow"
        >
          <b-card-title class="text-dark">
            <h5 class="text-dark"><strong>EN PROCESO</strong></h5>
          </b-card-title>
          <b-card-text>
            <div class="float-left">
              <h3 class="text-dark font-weight-bold">
                {{ process }}
              </h3>
            </div>
            <div class="float-right">
              <b-avatar
                size="lg"
                variant="primary"
              >
                <feather-icon icon="DollarSignIcon" />
              </b-avatar>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="6"
        xs="12"
      >
        <b-card
          bg-variant="white"
          text-variant="dark"
          class="shadow"
        >
          <b-card-title class="text-dark">
            <h5 class="text-dark"><strong>PENDIENTES</strong></h5>
          </b-card-title>
          <b-card-text>
            <div class="float-left">
              <h3 class="text-dark font-weight-bold">
                {{ pending }}
              </h3>
            </div>
            <div class="float-right">
              <b-avatar
                size="lg"
                variant="primary"
              >
                <feather-icon icon="PenToolIcon" />
              </b-avatar>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
      <b-col
        lg="3"
        md="6"
        sm="6"
        xs="12"
      >
        <b-card
          bg-variant="white"
          text-variant="dark"
          class="shadow"
        >
          <b-card-title class="text-dark">
            <h5 class="text-dark"><strong>FINALIZADOS</strong></h5>
          </b-card-title>
          <b-card-text>
            <div class="float-left">
              <h3 class="text-dark font-weight-bold">
                {{ finish }}
              </h3>
            </div>
            <div class="float-right">
              <b-avatar
                size="lg"
                variant="primary"
              >
                <feather-icon icon="PackageIcon" />
              </b-avatar>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BAvatar,
} from 'bootstrap-vue'
import MainService from './services/MainService'
import errorsServices from '@/libs/errorsServices'
import permissions from '@/libs/permissions'

export default {
  name: 'MainPage',
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BAvatar,
  },
  data() {
    return {
      categories: 0,
      open_tickets: 0,
      close_tickets: 0,
      agents: 0,
      received: 0,
      address: 0,
      process: 0,
      pending: 0,
      finish: 0,
    }
  },
  async created() {
    await this.onHandleCards()
  },
  methods: {
    accessPermissions(permission) {
      return permissions(permission)
    },
    errorResp(error) {
      const err = errorsServices(error)

      this.swal(err, 'error')
    },
    swal(text, type) {
      this.$swal({
        title: text,
        icon: type,
        showCancelButton: false,
        showConfirmButton: true,
        confirmButtonText: 'Aceptar',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        showClass: {
          popup: 'animate__animated animate__bounceIn',
        },
        buttonsStyling: false,
      })
    },
    async onHandleCards() {
      if (this.accessPermissions('dashboard.index')) {
        await MainService.handleList().then(response => {
          if (response.data.data) {
            this.categories = response.data.data.categories
            this.open_tickets = response.data.data.ticketsOpen
            this.close_tickets = response.data.data.ticketsClosed
            this.agents = response.data.data.agent
            this.received = response.data.data.orderReceived
            this.process = response.data.data.orderInProcess
            this.pending = response.data.data.orderPending
            this.finish = response.data.data.orderClosed
          }
        }).catch(error => this.errorResp(error))
      }
    },
  },
}
</script>

<style>

</style>
