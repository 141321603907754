import VueJwtDecode from 'vue-jwt-decode'
import useJwt from '@/auth/jwt/useJwt'

/**
 * comparacion de permisos
 * @param permission
 * @returns {boolean}
 */
const permissions = permission => {
  // buscar el token
  const token = useJwt.getToken()
  
  if (token) {
    // decodificar el token
    const decode = VueJwtDecode.decode(token)
    
    // si no tiene permisos
    if (decode.permissions || decode.permissions.length > 0) {
      // buscar el permiso
      return decode.permissions.find(value => value === permission) ? true : false
    }
  }
  
  // de lo contrario no dejara hacerlo
  return false
}

export default permissions
